import React from 'react';
import Layout from '../components/layout';

const PageNotFound = () => (
  <Layout pageTitle="404 || Gamily">
    <h3>404 - Page Not Found</h3>
  </Layout>
);

export default PageNotFound;
